<template>
  <v-btn
    outlined
    :icon="icon"
    :small="small"
    :color="icon ? color : bgColor"
    :style="{ color: color }"
    v-bind="$attrs"
    v-on="$listeners"
    :class="[
      'elevation-0 font-weight-bold btn-style',
      small && !icon && 'btn-padding-small btn-small',
      !small && !icon && 'btn-padding btn-normal',
      icon ? bgColor : withIcon ? 'btn-with-icon' : 'btn-style-normal',
      primary && 'primary',
      disabled && 'disabled'
    ]"
    :ripple="false"
    :disabled="disabled"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  props: {
    color: {
      default: "white",
    },
    bgColor: {
      default: "primary",
    },
    icon: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    preventDefault: {
      type: Boolean,
      default: true,
    },
    stopPropagation: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.btn-style {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 6px;
  margin-bottom: 6px;

  &.btn-padding {
    padding-left: 26px;
    padding-right: 26px;
  }

  &.btn-padding-small {
    padding-left: 8px;
    padding-right: 8px;
  }

  &.btn-style-normal {
    min-width: 88px !important;
    background-color: white;
    border-color: var(--v-border-base);

    &:hover {
      border-color: var(--v-border_dark-base) !important;
    }

    &.primary {
      background-color: var(--v-primary-base) !important;
      color: white !important;
    }

    &.disabled {
      background-color: var(--v-disabled_item-base) !important;
      border-color: var(--v-disabled_item-base) !important;
      color: white !important;
      cursor: not-allowed !important;
    }
  }

  &.btn-normal {
    min-height: 44px !important;
  }

  &.btn-small {
    min-height: 2rem !important;
  }

  &.btn-with-icon {
    min-width: 48px !important;
    max-width: 48px !important;
    min-height: 48px !important;
    max-height: 48px !important;
    background-color: white;
    border-color: var(--v-border-base);
  }
}
</style>