var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-btn',_vm._g(_vm._b({class:[
    'elevation-0 font-weight-bold btn-style',
    _vm.small && !_vm.icon && 'btn-padding-small btn-small',
    !_vm.small && !_vm.icon && 'btn-padding btn-normal',
    _vm.icon ? _vm.bgColor : _vm.withIcon ? 'btn-with-icon' : 'btn-style-normal',
    _vm.primary && 'primary',
    _vm.disabled && 'disabled'
  ],style:({ color: _vm.color }),attrs:{"outlined":"","icon":_vm.icon,"small":_vm.small,"color":_vm.icon ? _vm.color : _vm.bgColor,"ripple":false,"disabled":_vm.disabled}},'v-btn',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }